body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: pointer;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
  border: 1px solid lightgray;
  /*border: 1px solid #895e12;*/
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  /*background-color: #BD8B40;*/
  border-radius: 20px;
}