.ck.ck-reset {
    height: 100% !important;
    position: relative;
    overflow: hidden;
}

.ck.ck-editor__main{
    height: 85% !important;
    position: relative;
    overflow: auto;
}

  .ck.ck-editor__main>.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){
    border-color: transparent;
    /* border-radius: 16px; */

}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    border-color: transparent;
    /* border-radius: 16px; */

}

.ck.ck-toolbar{
    border-color:transparent;
    background-color: transparent;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){
    border-color: transparent;
    min-height: 100%;
}